import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { ICasinoGameDetails } from 'bp-framework/dist/casino/casino.interface';

@Component({
  selector: 'app-casino-game-thumbnail',
  standalone: true,
  imports: [CommonModule, IonicModule],
  templateUrl: './casino-game-thumbnail.component.html',
  styleUrl: './casino-game-thumbnail.component.scss'
})
export class CasinoGameThumbnailComponent {
  @Input() game!: Partial<ICasinoGameDetails<any, any>>;
}
