import { TranslateService } from '@ngx-translate/core';

import { ICasinoGameDetails } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';

export const convertFilterParamsToText = (
  keyword: string,
  selectedCategory: Partial<IListItem<number>> | null,
  selectedSubCategory: Partial<IListItem<number>> | null,
  selectedVendor: Partial<IListItem<number>> | null,
  translateService: TranslateService
): string => {
  return translateService.instant('commons.casinoNoResultsForSelectedFilters', {
    keyword: keyword || '--',
    category: selectedCategory?.label || '--',
    subcategory: selectedSubCategory?.label || '--',
    provider: selectedVendor?.label || '--'
  });
};

export const populateTagIds = (
  selectedCategory: Partial<IListItem<number>> | null,
  selectedSubCategory: Partial<IListItem<number>> | null,
  selectedVendor: Partial<IListItem<number>> | null
): string[] => {
  const tmpTagIds: string[] = [];

  if (selectedCategory?.value) {
    tmpTagIds.push(`${selectedCategory.value}`);
  }

  if (selectedSubCategory?.value) {
    tmpTagIds.push(`${selectedSubCategory.value}`);
  }

  if (selectedVendor?.value) {
    tmpTagIds.push(`${selectedVendor.value}`);
  }

  return tmpTagIds;
};

export const calculateInitialBreakpoint = (fullHeight: number): number => {
  const adjustedHeight = fullHeight - 80; // 80px is the height of the header on desktop
  const heightRatio = adjustedHeight / fullHeight;
  return heightRatio;
};

export const updateFavoriteGamesList = (
  game: Partial<ICasinoGameDetails<any, any>>,
  shouldBeRemovedFromFavorites: boolean,
  allGames: Partial<ICasinoGameDetails<any, any>>[]
): Partial<ICasinoGameDetails<any, any>>[] => {
  const tmpList: Partial<ICasinoGameDetails<any, any>>[] = allGames?.filter((item: Partial<ICasinoGameDetails<any, any>>) => item?.id !== game?.id);

  if (!shouldBeRemovedFromFavorites) {
    tmpList.push(game);
  }

  return tmpList;
};
