import { Routes } from '@angular/router';

import { guardUntilAuthenticated, runGuardsInOrder } from 'bp-angular-library';

import { guardNavigationCodes } from 'src/app/pages-surfpoint/guards/navigation-codes.guard';
import { guardSurfpointNavigationIfPlayerBlocked } from 'src/app/pages-surfpoint/guards/player-blocked.guard';

export const SURFPOINT_ROUTE_PATHS = {
  notFound: 'not-found',
  login: 'login',
  landing: 'landing',
  lobby: 'lobby',
  casino: 'casino',
  surfpoint: 'surfpoint'
};

export const BETPLATFORM_DEV_SURFPOINT_ROUTES: Routes = [
  //#region Main
  {
    path: SURFPOINT_ROUTE_PATHS.login,
    loadComponent: () => import('../../../../pages/login/login.component').then(m => m.LoginComponent)
  },
  //#endregion Main
  //#region Surfpoint
  {
    path: SURFPOINT_ROUTE_PATHS.surfpoint,
    children: [
      {
        path: SURFPOINT_ROUTE_PATHS.landing,
        loadComponent: () => import('../../../../pages-surfpoint/surfpoint-landing/surfpoint-landing.component').then(m => m.SurfpointLandingComponent),
        canActivate: [runGuardsInOrder(guardUntilAuthenticated())]
      },
      {
        path: SURFPOINT_ROUTE_PATHS.lobby,
        loadComponent: () => import('../../../../pages-surfpoint/surfpoint-lobby/surfpoint-lobby.component').then(m => m.SurfpointLobbyComponent),
        canActivate: [runGuardsInOrder(guardUntilAuthenticated())]
      },
      {
        path: SURFPOINT_ROUTE_PATHS.casino,
        loadComponent: () => import('../../../../pages-surfpoint/surfpoint-casino/surfpoint-casino.component').then(m => m.SurfpointCasinoComponent),
        canActivate: [runGuardsInOrder(guardUntilAuthenticated(), guardSurfpointNavigationIfPlayerBlocked(), guardNavigationCodes())]
      },
      {
        path: `${SURFPOINT_ROUTE_PATHS.casino}/:gameId`,
        loadComponent: () => import('../../../../pages-surfpoint/surfpoint-play-game/surfpoint-play-game.component').then(m => m.SurfpointPlayGameComponent),
        canActivate: [runGuardsInOrder(guardUntilAuthenticated(), guardSurfpointNavigationIfPlayerBlocked(), guardNavigationCodes())]
      },
      {
        path: '',
        redirectTo: SURFPOINT_ROUTE_PATHS.landing,
        pathMatch: 'full'
      }
    ]
  },
  //#endregion Surfpoint
  {
    path: 'casino-game-iframe-error',
    redirectTo: SURFPOINT_ROUTE_PATHS.notFound,
    pathMatch: 'full'
  },
  {
    path: SURFPOINT_ROUTE_PATHS.notFound,
    loadComponent: () => import('../../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: '',
    redirectTo: SURFPOINT_ROUTE_PATHS.surfpoint,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: SURFPOINT_ROUTE_PATHS.notFound
  }
];
