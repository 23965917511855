import { Routes } from '@angular/router';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import {
  CasinoGamePlayMode,
  CasinoTagType,
  ICasinoGameDetails,
  ICasinoGameLaunchDetails,
  ICasinoSearchParams,
  ICasinoTagDetails,
  IJackpot
} from 'bp-framework/dist/casino/casino.interface';
import { IListItem, IMainPage, INavGroup, INavigationItem } from 'bp-framework/dist/common/common.interface';
import { IAffiliateCode, IReferredPlayer } from 'bp-framework/dist/player/player.interface';
import { ICryptoDepositInit, ITransactionDetails, TransactionType } from 'bp-framework/dist/transactions/transactions.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { BehaviorSubject } from 'rxjs';

export abstract class UserAbstractService {
  abstract playerRegisterWithUsernameAndPassword(username: string, password: string, phone: string, affiliateCode: string): Promise<Partial<IUserDetails> | null>;
  abstract loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null>;
  abstract changePassword(oldPassword: string, newPassword: string): Promise<string | null>;
  abstract refreshToken(): Promise<Partial<IUserDetails> | null>;
  abstract getUserProfile(): Promise<Partial<IUserDetails> | null>;
  abstract patchUserLanguage(langAlpha2Code: string): Promise<Partial<IUserDetails> | null>;
  abstract updateUserWithProfileData(): Promise<Partial<IUserDetails> | null>;
  abstract watchForLanguageChange(): void;
  abstract getAffiliatePlayers(): Promise<Partial<IReferredPlayer>[] | null>;
  abstract getAffiliateCodes(): Promise<Partial<IAffiliateCode>[] | null>;
  // TODO: Check should we create separate class for Player
  abstract getPlayerTransactions(
    start_date_iso: string,
    end_date_iso: string,
    limit: number,
    offset: number,
    transaction_types: TransactionType[]
  ): Promise<Partial<ITransactionDetails>[] | null>;
  abstract makeCryptoWithdrawRequest(wallet_id: number, amount: number, crypto_wallet_address: string): Promise<Partial<ITransactionDetails> | null>;
  abstract cancelCryptoWithdrawRequest(request_id: string): Promise<Partial<ITransactionDetails> | null>;
  abstract getCryptoDepositInit(): Promise<ICryptoDepositInit | null>;
}

export abstract class CasinoAbstractService {
  // Tags | Vendors | Categories | Subcategories
  abstract getCasinoTags(): Promise<IApiPayload<Partial<ICasinoTagDetails>[]>>;
  abstract getCasinoTypesOfTags(): Promise<IApiPayload<Partial<CasinoTagType>[]>>;
  abstract getCasinoGameVendors(): Promise<IApiPayload<Partial<IListItem<number>>[]>>;
  abstract getAllCasinoCategories(): Promise<IApiPayload<Partial<IListItem<number>>[]>>;
  abstract getSubcategoriesByCategoryId(categoryId: string): Promise<IApiPayload<Partial<IListItem<number>>[]>>;
  // Games
  abstract getCasinoGames(params: ICasinoSearchParams): Promise<IApiPayload<ICasinoGameDetails<any, any>[]>>;
  abstract getCasinoGameById(gameId: number): Promise<ICasinoGameDetails<any, any> | null>;
  abstract getDetailsToLaunchGame(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGameLaunchDetails>;
  abstract jackpots$: BehaviorSubject<IJackpot[] | null>;
  abstract getJackpotsListForPlayers(): Promise<any>;
  abstract updateJackpotValue(jackpot: IJackpot): void;
  abstract processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void;
  // Favorites
  abstract getFavoriteGames(): Promise<Partial<ICasinoGameDetails<any, any>>[]>;
  abstract getIdsOfFavoriteGames(): Promise<number[]>;
  abstract addGameToFavorites(gameId: number): Promise<void>;
  abstract removeGameFromFavorites(gameId: number): Promise<void>;
}

export abstract class BettingAbstractService {
  abstract test(): void;
}

export abstract class BackendEventsAbstractService {
  abstract subscribeToBackendEvents(): void;
}

export abstract class ContentAbstractService {
  abstract getListOfPrimaryPages(): Promise<Partial<IMainPage<number>>[]>;
  abstract buildAllRoutes(): Routes;
  abstract buildSidemenuRoutes(): Partial<INavGroup>[];
  abstract buildMainMenuRoutes(): Partial<INavigationItem<number>>[];
  abstract buildSiteFooterNavLinks(): Partial<INavGroup>[];
  abstract buildSecondaryPagesNavLinks(): Partial<INavGroup>[];
}
