import { inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { DialogsService } from 'bp-angular-library';

import { CasinoService } from 'src/app/pages-casino/casino.service';

export type IFrameMessageType = 'from:app:another_instance_is_loaded_inside_iframe';

export interface IFrameMessage {
  type: IFrameMessageType;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class IframeHandlerService implements OnDestroy {
  private router: Router = inject(Router);
  private dialogsService: DialogsService = inject(DialogsService);
  private casinoService: CasinoService = inject(CasinoService);
  private translateService: TranslateService = inject(TranslateService);

  //#region lifecycle hooks
  ngOnDestroy(): void {
    this.unsubscribe();
  }
  //#endregion

  //#region initialization
  public async initialize(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const isInsideAnIframe = this.windowIsInsideIframe();
      console.log('isInsideAnIframe >>> ', isInsideAnIframe);

      if (isInsideAnIframe) {
        this.sendMessageToIframe({ type: 'from:app:another_instance_is_loaded_inside_iframe' });

        setTimeout(() => {
          this.dialogsService.presentToast({ message: 'iframe handler initialized' });
        }, 10000);
        return resolve();
      }

      this.subscribeToIframeEvents();

      return resolve();
    });
  }

  private windowIsInsideIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  //#endregion

  //#region iframe communication
  public sendMessageToIframe(data: IFrameMessage, targetOrigin = '*', target: Window | null = null): void {
    const iframe = target || window.parent;

    if (!iframe) {
      // TODO: Handle error or display message to the user
      return;
    }

    iframe.postMessage(data, targetOrigin);
  }

  private async handleIframeMessage(event: MessageEvent<IFrameMessage>): Promise<void> {
    if (!event?.data?.type) {
      // TODO: Handle error or display message to the user
      return;
    }

    if (event?.data?.type === 'from:app:another_instance_is_loaded_inside_iframe') {
      this.handleAnotherInstanceIsLoadedInsideIframe();
    }
  }
  //#endregion

  //#region utils
  private handleAnotherInstanceIsLoadedInsideIframe(): void {
    let path = `/casino/games`;

    if (this.casinoService.selectedGame$.value) {
      path = `/casino/games/${this.casinoService.selectedGame$.value.id}/preview`;
    }

    const tmpMessage: string = this.translateService.instant('notifications.somethingWenWrongWhilePlayingGameInIframe');
    this.dialogsService.presentToast({ message: tmpMessage, color: 'danger' });

    // Theres been an error while you ware playing the game. Please try again or contact support.

    this.router.navigateByUrl(path);
  }
  //#endregion

  //#region subscribe/unsubscribe
  public subscribeToIframeEvents(): void {
    window.addEventListener('message', (event: MessageEvent) => this.handleIframeMessage(event));
  }

  private unsubscribe(): void {
    window.removeEventListener('message', (event: MessageEvent) => this.handleIframeMessage(event));
  }
  //#endregion
}
