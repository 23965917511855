import { inject, Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import {
  CasinoGamePlayMode,
  CasinoTagType,
  ICasinoGameDetails,
  ICasinoGameLaunchDetails,
  ICasinoSearchParams,
  ICasinoTagDetails,
  IJackpot
} from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import {
  CasinoGame,
  CasinoLaunchDetails,
  ICasinoSearchParams as IBPCasinoGamesSearchParams,
  IJackpotItem
} from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import { mapCasinoGames, mapCasinoJackpots } from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';

import { BpCasinoApiService } from 'bp-angular-library';

import { CasinoAbstractService, UserAbstractService } from '../../env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { JackpotWinnerComponent } from '../../../../shared/components/casino/jackpot-winner/jackpot-winner.component';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoBetplatformService extends CasinoAbstractService {
  private bpCasinoApiService: BpCasinoApiService = inject(BpCasinoApiService);
  private translateService: TranslateService = inject(TranslateService);
  private authService: AuthenticationService = inject(AuthenticationService);

  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private modalController: ModalController = inject(ModalController);

  public jackpots$: BehaviorSubject<IJackpot[] | null> = new BehaviorSubject<IJackpot[] | null>(null);

  constructor() {
    super();
    this.getJackpotsList();
  }

  public async getCasinoTags(): Promise<IApiPayload<ICasinoTagDetails[]>> {
    return new Promise<IApiPayload<ICasinoTagDetails[]>>(async (resolve, reject) => {
      try {
        const response: IBpPayload<ICasinoTagDetails[]> | null = await this.bpCasinoApiService.getCasinoTags({});
        const tmpResponse: IApiPayload<ICasinoTagDetails[]> = {
          data: response?.data || [],
          total: response?.total
        };

        resolve(tmpResponse);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino tags'));
      }
    });
  }

  public async getCasinoTypesOfTags(): Promise<IApiPayload<CasinoTagType[]>> {
    // TODO: Revisit return types here. The "CasinoTagType" is not the same as "CasinoTagTypes" from the API
    return new Promise<IApiPayload<CasinoTagType[]>>(async (resolve, reject) => {
      try {
        const response: IBpPayload<CasinoTagType[]> | null = await this.bpCasinoApiService.getCasinoTypesOfTags();
        const tmpResponse: IApiPayload<CasinoTagType[]> = {
          data: response?.data || [],
          total: response?.total
        };
        resolve(tmpResponse);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino tag types'));
      }
    });
  }

  public async getAllCasinoCategories(): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    return new Promise<IApiPayload<Partial<IListItem<number>>[]>>(async (resolve, reject) => {
      try {
        const response: IApiPayload<ICasinoTagDetails[]> = await this.getCasinoTags();

        console.log('Casino categories ==== tags: ', response);
        const tmpResponse: IApiPayload<Partial<IListItem<number>>[]> = {
          data:
            response?.data
              ?.filter((item: ICasinoTagDetails) => (item?.type as CasinoTagType) === 'group')
              ?.map((item: ICasinoTagDetails) => {
                const tmpItem: Partial<IListItem<number>> = {
                  value: item?.id,
                  label: item?.title,
                  imgSrc: item?.image_url || '',
                  sortOrder: item?.sort_order
                };

                return tmpItem;
              }) || [],
          total: response?.total
        };

        resolve(tmpResponse);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino games categories'));
      }
    });
  }

  public async getSubcategoriesByCategoryId(): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    return new Promise<IApiPayload<Partial<IListItem<number>>[]>>(async (resolve, reject) => {
      try {
        const response: IApiPayload<ICasinoTagDetails[]> = await this.getCasinoTags();

        const tmpResponse: IApiPayload<Partial<IListItem<number>>[]> = {
          data:
            response?.data
              ?.filter((item: ICasinoTagDetails) => (item?.type as CasinoTagType) === 'sub-category')
              ?.map((item: ICasinoTagDetails) => {
                const tmpItem: Partial<IListItem<number>> = {
                  value: item?.id,
                  label: item?.title,
                  imgSrc: item?.image_url || ''
                };

                return tmpItem;
              }) || [],
          total: response?.total
        };

        resolve(tmpResponse);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino game subcategories'));
      }
    });
  }

  public async getCasinoGameVendors(): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    return new Promise<IApiPayload<Partial<IListItem<number>>[]>>(async (resolve, reject) => {
      try {
        // TODO: IMPORTANT: Refactor this and similar methods. We can now fetch exact type and don't need to filter them on the frontend
        const response: IApiPayload<ICasinoTagDetails[]> = await this.getCasinoTags();

        const tmpResponse: IApiPayload<Partial<IListItem<number>>[]> = {
          data:
            response?.data
              ?.filter((item: ICasinoTagDetails) => (item?.type as CasinoTagType) === 'vendor')
              ?.map((item: ICasinoTagDetails) => {
                const tmpItem: Partial<IListItem<number>> = {
                  value: item?.id,
                  label: item?.title,
                  imgSrc: item?.image_url || ''
                };

                return tmpItem;
              }) || [],
          total: response?.total
        };

        resolve(tmpResponse);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino game vendors'));
      }
    });
  }

  public async getCasinoGames(params: ICasinoSearchParams): Promise<IApiPayload<ICasinoGameDetails<any, any>[]>> {
    return new Promise<IApiPayload<ICasinoGameDetails<any, any>[]>>(async (resolve, reject) => {
      try {
        const tmpParams: IBPCasinoGamesSearchParams = {
          offset: params?.offset,
          limit: params?.limit,
          query: params?.query,
          tag_title: params?.tagTitle,
          tag_type: params?.tagType,
          tag_ids: Array.isArray(params?.tagIds) ? params.tagIds : undefined,
          vendor: params?.vendor
        };
        const response: IBpPayload<CasinoGame[]> | null = await this.bpCasinoApiService.getAllCasinoGames(tmpParams);
        const tmpResponse: IApiPayload<ICasinoGameDetails<any, any>[]> = {
          data: response?.data && Array.isArray(response?.data) ? mapCasinoGames(response.data) : [],
          offset: response?.offset,
          limit: response?.limit,
          total: response?.total
        };

        resolve(tmpResponse);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  public async getCasinoGameById(id: number): Promise<ICasinoGameDetails<any, any>> {
    return new Promise<ICasinoGameDetails<any, any>>(async (resolve, reject) => {
      try {
        const response: IBpPayload<CasinoGame> | null = await this.bpCasinoApiService.getCasinoGameById(id);

        if (!response?.data?.id) {
          return reject(new Error('Failed to retreive the casino game. Data is missing'));
        }

        resolve(mapCasinoGames([response.data])[0]);
      } catch (error) {
        return reject(new Error('Failed to retreive the casino game. Call to backend failed'));
      }
    });
  }

  public async getDetailsToLaunchGame(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: IBpPayload<CasinoLaunchDetails> | null = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId, mode);
        resolve({ url: response?.data?.launchUrl, token: response?.data?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  // TODO: Remove this method and replace it with getDetailsToLaunchGame or vice versa
  // TODO: Check if there are some other api calls that are not used and remove them
  public async getSingleCasinoGameToPlay(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: any = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId, mode);
        resolve(response);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  public async getJackpotsListForPlayers(): Promise<IJackpot[] | null> {
    return new Promise<IJackpot[] | null>(async (resolve, reject) => {
      try {
        const jackpots: IBpPayload<IJackpotItem[]> | null = await this.bpCasinoApiService.getJackpotsListForPlayers();

        if (!jackpots?.data) {
          return resolve(null);
        }

        resolve(mapCasinoJackpots(jackpots?.data));
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  /////// JACKPOTS SERVICE

  public processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void {
    if (playerId === this.authService.user$.value?.id) {
      this.presentJackpotWinnerModal(jackpot);
      this.userAbstractService.updateUserWithProfileData();
    }
  }

  public async getJackpotsList(): Promise<void> {
    const tmpValue: IJackpot[] | null = await this.getJackpotsListForPlayers();
    this.jackpots$.next(tmpValue);
  }

  public updateJackpotValue(jackpot: IJackpot): void {
    const tmpJackpots: IJackpot[] = this.jackpots$.value || [];
    const index: number = tmpJackpots.findIndex(item => item.id === jackpot.id);

    if (index !== -1) {
      tmpJackpots[index] = { ...tmpJackpots[index], ...jackpot };
      this.jackpots$.next(tmpJackpots);
    }
  }

  private async presentJackpotWinnerModal(jackpotDetails: IJackpot): Promise<void> {
    const modal = await this.modalController.create({
      component: JackpotWinnerComponent,
      componentProps: {
        jackpotDetails
      },
      cssClass: 'full-screen-modal'
    });

    await modal.present();

    setTimeout(() => {
      modal.dismiss();
    }, 10000);

    // const { data, role } = await modal.onWillDismiss();

    // if (role === 'confirm') {
    //   // console.log('SoftKeyboardComponent closed and confirmed');
    // }
  }

  // FAVORITES
  public async getFavoriteGames(): Promise<Partial<ICasinoGameDetails<any, any>>[]> {
    return new Promise<Partial<ICasinoGameDetails<any, any>>[]>(async (resolve, reject) => {
      try {
        const response: IBpPayload<CasinoGame> | null = await this.bpCasinoApiService.getFavoriteGames();
        const mapped: Partial<ICasinoGameDetails<any, any>>[] = response?.data && Array.isArray(response?.data) ? mapCasinoGames(response.data) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveFavoriteGames')));
      }
    });
  }

  public async getIdsOfFavoriteGames(): Promise<number[]> {
    return new Promise<number[]>(async (resolve, reject) => {
      try {
        const response: IBpPayload<number[]> | null = await this.bpCasinoApiService.getIdsOfFavoriteGames();
        resolve(response?.data || []);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveFavoriteGames')));
      }
    });
  }

  public async addGameToFavorites(gameId: number): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.bpCasinoApiService.addGameToFavorites(gameId);
        resolve();
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToAddGameToFavorites')));
      }
    });
  }

  public async removeGameFromFavorites(gameId: number): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.bpCasinoApiService.removeGameFromFavorites(gameId);
        resolve();
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRemoveGameFromFavorites')));
      }
    });
  }
}
