import { inject, Injectable } from '@angular/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';

import { CasinoAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { CasinoService } from '../../../../pages-casino/casino.service';

@Injectable({
  providedIn: 'root'
})
export class CasinoCategoriesStripeService {
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  private casinoService: CasinoService = inject(CasinoService);

  public categories: Partial<IListItem<number>>[] = [];

  constructor() {
    this.initialize();
  }

  public async initialize(): Promise<void> {
    try {
      const tmpPayload: IApiPayload<Partial<IListItem<number>>[]> = await this.casinoAbstractService.getAllCasinoCategories();

      const sorted: Partial<IListItem<number>>[] = tmpPayload?.data?.sort((a: Partial<IListItem<number>>, b: Partial<IListItem<number>>) => {
        if (!a.sortOrder || !b.sortOrder) {
          return 99999;
        }
        return a.sortOrder - b.sortOrder;
      });

      this.categories = sorted || [];

      console.log('Casino categories:', this.categories);

      if (this.categories[0]) {
        this.handleSelectedCategoryChange(this.categories[0]);
      }
    } catch (error) {
      console.error('Error while fetching casino categories', error);
    }
  }

  public handleSelectedCategoryChange(category: Partial<IListItem<number>>): void {
    this.casinoService.selectedSubCategory$.next(null);
    this.casinoService.selectedCategory$.next(category);
  }
}
