import { inject, Injectable } from '@angular/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';

import { CasinoAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { CasinoService } from '../../../../pages-casino/casino.service';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoProvidersService {
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  private casinoService: CasinoService = inject(CasinoService);

  public allProvidersFromBackend$: BehaviorSubject<Partial<IListItem<number>>[]> = new BehaviorSubject<Partial<IListItem<number>>[]>([]);

  constructor() {
    this.initialize();
  }

  public async initialize(): Promise<void> {
    const tmpPayload: IApiPayload<Partial<IListItem<number>>[]> = await this.casinoAbstractService.getCasinoGameVendors();
    this.allProvidersFromBackend$.next(tmpPayload?.data || []);
  }
}
