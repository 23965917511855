import { CommonModule } from '@angular/common';
import { Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { IonicModule, MenuController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { IListItem } from 'bp-framework/dist/common/common.interface';

import { CasinoService } from '../../../../pages-casino/casino.service';
import { CasinoProvidersService } from './casino-providers.service';

import { filterProviderLabelsByKeyword } from './casino-providers.utils';

@Component({
  selector: 'app-casino-providers',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './casino-providers.component.html',
  styleUrls: ['./casino-providers.component.scss']
})
export class CasinoProvidersComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private casinoService: CasinoService = inject(CasinoService);
  private menuController: MenuController = inject(MenuController);
  public casinoProvidersService: CasinoProvidersService = inject(CasinoProvidersService);

  @Input() showSearch = true;
  @Input() collapsible = false;
  @Input() isCollapsed = false;

  @HostBinding('class.collapsible') get isCollapsible() {
    return this.collapsible;
  }

  @HostBinding('class.is-collapsed') get uiIsCollapsed() {
    return this.isCollapsed;
  }

  public filteredProviders: Partial<IListItem<number>>[] = [];

  private mobileSearchKeyword = '';

  ngOnInit(): void {
    this.casinoProvidersService.allProvidersFromBackend$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((providers: Partial<IListItem<number>>[]) => {
      this.filteredProviders = filterProviderLabelsByKeyword(this.mobileSearchKeyword, providers);
    });

    this.casinoService.selectedProvider$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((provider: Partial<IListItem<number>> | null) => {
      if (!provider) {
        this.markAllProvidersAsUnselected();
      }
    });
  }

  private markAllProvidersAsUnselected(): void {
    const updatedProviders: Partial<IListItem<number>>[] = this.casinoProvidersService.allProvidersFromBackend$.value.map((item: Partial<IListItem<number>>) => {
      item.selected = false;
      return item;
    });

    this.casinoProvidersService.allProvidersFromBackend$.next(updatedProviders);
  }

  private toggleSelectedStateForAllProviders(provider: Partial<IListItem<number>>): void {
    const elementShouldBeSelected = !provider.selected;
    provider.selected = elementShouldBeSelected;

    const updatedProviders: Partial<IListItem<number>>[] = this.casinoProvidersService.allProvidersFromBackend$.value.map((item: Partial<IListItem<number>>) => {
      item.selected = false;

      if (item.value === provider.value && elementShouldBeSelected) {
        item.selected = true;
      }

      return item;
    });

    this.casinoProvidersService.allProvidersFromBackend$.next(updatedProviders);
  }

  public handleProviderSelection(provider: Partial<IListItem<number>>): void {
    this.toggleSelectedStateForAllProviders(provider);
    // Business logic - When the provider is selected, then we want to reset the selected subcategory
    this.casinoService.selectedProvider$.next(provider?.selected ? provider : null);
    this.casinoService.selectedSubCategory$.next(null);

    if (!this.collapsible) {
      this.menuController.close();
      return;
    }
  }

  public handleArrowBtnClicked(): void {
    if (!this.collapsible) {
      this.menuController.close();
      return;
    }

    if (this.collapsible) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  public handleKeywordChange(event: Event): void {
    if (!this.showSearch) {
      return;
    }

    this.mobileSearchKeyword = (event as CustomEvent)?.detail?.value;
    this.filteredProviders = filterProviderLabelsByKeyword(this.mobileSearchKeyword, this.casinoProvidersService.allProvidersFromBackend$.value);
  }
}
