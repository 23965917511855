import { mergeWith } from 'bp-framework/dist/common/common.utils';
import { IEnvApiOn1x2Team } from 'bp-framework/dist/env-specific/1x2team/configuration/configuration.interface';
import { PROJECT_PREFIX } from 'bp-framework/dist/storage/storage.const';

import { IEnvConfigPlayerFrontend } from 'src/app/shared/models/configuration/configuration.interface';

export const BASE_URLS_1X2TEAM = {
  office: 'https://office.1x2team.com',
  casino: 'https://office.1x2team.com',
  data: 'https://data.1x2team.com',
  feed: 'https://webfeed.zerosoft.bet'
};

const API_CONFIG_1X2TEAM: IEnvApiOn1x2Team = {
  provider: '1x2team',
  baseUrls: {
    office: BASE_URLS_1X2TEAM.office,
    casino: BASE_URLS_1X2TEAM.office,
    data: BASE_URLS_1X2TEAM.data,
    feed: BASE_URLS_1X2TEAM.feed
  },
  coreAuthEndpoints: {
    playerLogin: 'user/login',
    adminLogin: 'clients/login',
    getProfile: 'player/profile'
  }
};

export const PROJECT_CONFIG_1X2TEAM_PROD: IEnvConfigPlayerFrontend<IEnvApiOn1x2Team> = {
  production: false,
  environmentName: 'prod',
  projectName: 'bp-player-frontend',
  projectPrefix: PROJECT_PREFIX.playerFrontendApp,
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_1X2TEAM.office, BASE_URLS_1X2TEAM.data],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_1X2TEAM.office}/user/login`, `${BASE_URLS_1X2TEAM.office}/clients/login`],
    skip401HandlingForTheseUrls: []
  },
  api: API_CONFIG_1X2TEAM,
  features: {
    frontendMode: 'default',
    storeCredentials: false
  }
};

export const PROJECT_CONFIG_1X2TEAM_PROD_SURFPOINT: IEnvConfigPlayerFrontend<IEnvApiOn1x2Team> = mergeWith(PROJECT_CONFIG_1X2TEAM_PROD, {
  features: { frontendMode: 'surfpoint' }
} as IEnvConfigPlayerFrontend<IEnvApiOn1x2Team>);

export const PROJECT_CONFIG_1X2TEAM_DEV: IEnvConfigPlayerFrontend<IEnvApiOn1x2Team> = {
  production: false,
  environmentName: 'dev',
  projectName: 'bp-player-frontend',
  projectPrefix: PROJECT_PREFIX.playerFrontendApp,
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_1X2TEAM.office, BASE_URLS_1X2TEAM.data],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_1X2TEAM.office}/user/login`, `${BASE_URLS_1X2TEAM.office}/clients/login`],
    skip401HandlingForTheseUrls: []
  },
  api: API_CONFIG_1X2TEAM,
  features: {
    frontendMode: 'default',
    storeCredentials: true
  }
};

export const PROJECT_CONFIG_1X2TEAM_DEV_SURFPOINT: IEnvConfigPlayerFrontend<IEnvApiOn1x2Team> = mergeWith(PROJECT_CONFIG_1X2TEAM_DEV, {
  features: { frontendMode: 'surfpoint' }
} as IEnvConfigPlayerFrontend<IEnvApiOn1x2Team>);
