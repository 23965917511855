import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { OverlayEventDetail } from '@ionic/core';

import { TranslateService } from '@ngx-translate/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { ICasinoGameDetails, ICasinoSearchParams } from 'bp-framework/dist/casino/casino.interface';
import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { IListItem, IMainPage } from 'bp-framework/dist/common/common.interface';
import { IDialogResponse } from 'bp-framework/dist/dialogs/dialog.interface';

import { DialogsService } from 'bp-angular-library';

import { CasinoAbstractService, ContentAbstractService } from '../core/env-specific/env-abstracts';

import { PlatformService } from '../core/services/platform/platform.service';

import { CasinoSearchModalComponent } from '../shared/components/casino/casino-search-modal/casino-search-modal.component';
import { SignInSignUpComponent } from '../shared/components/forms/sign-in-sign-up/sign-in-sign-up.component';

import { IPaginationInfo } from '../shared/models/pagination/pagination.interface';
import { getPaginationInfo } from '../shared/models/pagination/pagination.utils';
import { ROUTE_PATHS } from '../shared/models/routing/routing.const';
import { SignUpOptionType, SiSuViewType } from '../shared/models/ui/ui.interface';
import { calculateInitialBreakpoint, convertFilterParamsToText, populateTagIds, updateFavoriteGamesList } from './casino.utils';
import { BehaviorSubject, combineLatest, debounceTime, from, pairwise, switchMap, tap } from 'rxjs';

const SEARCH_LIMIT_INCREMENT = 50;
const INITIAL_SEARCH_OFFSET = 0;

type CombinedFilterParams = [Partial<IListItem<number>> | null, Partial<IListItem<number>> | null, Partial<IListItem<number>> | null];

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  private platformService: PlatformService = inject(PlatformService);
  private dialogsService: DialogsService = inject(DialogsService);
  private router: Router = inject(Router);
  private translateService: TranslateService = inject(TranslateService);
  private contentAbstractService: ContentAbstractService = inject(ContentAbstractService);

  public allCasinoGames$: BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]> = new BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]>([]);
  // TODO: At the moment, we will get list of IDs for favorite games. In the future, we might need to get the whole list of favorite games with their details. For that user `getFavoriteGames()`
  public playerFavoriteGamesList$: BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]> = new BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]>([]);

  public selectedCategory$: BehaviorSubject<Partial<IListItem<number>> | null> = new BehaviorSubject<Partial<IListItem<number>> | null>(null);
  public selectedSubCategory$: BehaviorSubject<Partial<IListItem<number>> | null> = new BehaviorSubject<Partial<IListItem<number>> | null>(null);
  public selectedProvider$: BehaviorSubject<Partial<IListItem<number>> | null> = new BehaviorSubject<Partial<IListItem<number>> | null>(null);
  public searchKeyword$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public casinoNoResultsForSelectedFilters!: string;

  public showSkeletonLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loadingMoreGamesInProgress = false;
  public showLoadMoreButton = true;

  public paginationInfo!: IPaginationInfo;

  public selectedGame$: BehaviorSubject<Partial<ICasinoGameDetails<any, any>> | null> = new BehaviorSubject<Partial<ICasinoGameDetails<any, any>> | null>(null);

  public mainPages$: BehaviorSubject<Partial<IMainPage<number>>[]> = new BehaviorSubject<Partial<IMainPage<number>>[]>([]);

  constructor() {
    this.initialize();
  }

  public async initialize(): Promise<void> {
    this.observeChangesOnFilterParams();
    this.updateTheListOfFavoriteGamesWithBackendData();
    this.getListOfPrimaryPages();
  }

  private async getListOfPrimaryPages(): Promise<void> {
    try {
      const pages: Partial<IMainPage<number>>[] = await this.contentAbstractService.getListOfPrimaryPages();
      console.log('++++++ +INITIALIZEING APP ++++++');
      console.log(pages);
      console.log('pages should be loadee');
      const sorted: Partial<IMainPage<number>>[] = pages.sort((a: Partial<IMainPage<number>>, b: Partial<IMainPage<number>>) => {
        if (!a.sortOrder || !b.sortOrder) {
          return 99999;
        }
        return a.sortOrder - b.sortOrder;
      });
      this.mainPages$.next(sorted);
    } catch (error) {
      console.error('getListOfPrimaryPages error', error);
    }
  }

  private observeChangesOnFilterParams(): void {
    combineLatest([this.selectedCategory$, this.selectedSubCategory$, this.selectedProvider$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        pairwise(),
        debounceTime(500),
        tap(() => {
          this.showSkeletonLoader$.next(true);
        }),
        switchMap(([prev, current]: [CombinedFilterParams, CombinedFilterParams]) => {
          const [prevCategory, prevSubCategory, prevProvider] = prev;
          const [currentCategory, currentSubCategory, currentProvider] = current;

          const tmpKeyword: string = EMPTY_STRING; // IMPORTANT: For now, we are not using the keyword as part of the 'local' search. Search by keyword is performed when the user selectes global serch
          const tmpCategory: Partial<IListItem<number>> | null = currentCategory || null;
          const tmpSubCategory: Partial<IListItem<number>> | null = currentSubCategory || null;
          const tmpProvider: Partial<IListItem<number>> | null = currentProvider || null;

          this.casinoNoResultsForSelectedFilters = convertFilterParamsToText(tmpKeyword, tmpCategory, tmpSubCategory, tmpProvider, this.translateService);

          // console.log('selectedCategory', selectedCategory);
          // console.log('selectedProvider', selectedProvider);
          // console.log('selectedSubCategory', selectedSubCategory);
          // console.log('keyword', keyword);
          const tmpParams: ICasinoSearchParams = {
            offset: INITIAL_SEARCH_OFFSET,
            limit: SEARCH_LIMIT_INCREMENT, // Each time we change the filter params, we should reset the limit to the initial value
            query: tmpKeyword || undefined,
            tagIds: populateTagIds(tmpCategory, tmpSubCategory, tmpProvider)
          };

          return from(this.casinoAbstractService.getCasinoGames(tmpParams));
        })
      )
      .subscribe((payload: IApiPayload<ICasinoGameDetails<any, any>[]>) => {
        if (this.selectedGame$.value) {
          this.selectedGame$.next(null);
          this.navigateTo('all-games');
        }

        this.paginationInfo = getPaginationInfo(payload);
        this.showLoadMoreButton = !this.paginationInfo?.pagination?.isLastPage;

        // IMPORTANT: IF any of the 'casino game search' params are changed, we can consider that as a new search and we can reset the selected game
        this.allCasinoGames$.next(payload?.data || []);

        this.showSkeletonLoader$.next(false);

        // TODO: On the Casino page, once the search criteria is changed, we might need to do a SCROLL TO TOP on the 'mainIonContentElRef' element
      });
  }

  public async handleLoadMoreGames(): Promise<void> {
    this.loadingMoreGamesInProgress = true;

    const tmpParams: ICasinoSearchParams = {
      offset: this.paginationInfo.nextPageOffset,
      limit: SEARCH_LIMIT_INCREMENT,
      query: EMPTY_STRING,
      tagIds: populateTagIds(this.selectedCategory$.value, this.selectedSubCategory$.value, this.selectedProvider$.value)
    };

    try {
      const tmpPayload: IApiPayload<ICasinoGameDetails<any, any>[]> = await this.casinoAbstractService.getCasinoGames(tmpParams);
      this.paginationInfo = getPaginationInfo(tmpPayload);
      this.showLoadMoreButton = !this.paginationInfo?.pagination?.isLastPage;

      this.allCasinoGames$.next([...this.allCasinoGames$.value, ...(tmpPayload?.data || [])]);
    } catch (error) {
      console.log('handleLoadMoreGames error', error);
    }

    setTimeout(() => {
      this.loadingMoreGamesInProgress = false;
    }, 500);
  }

  public async openSearchModal(): Promise<void> {
    const isMobileResolution: boolean = this.platformService.isMobileResolution();
    const initialBreakpoint: number = isMobileResolution ? 1 : calculateInitialBreakpoint(this.platformService.height());

    const dialogResult: OverlayEventDetail<IDialogResponse<any>> = await this.dialogsService.presentModal({
      component: CasinoSearchModalComponent,
      componentProps: {
        showHeader: isMobileResolution
      },
      cssClass: 'is-casino-search-modal',
      initialBreakpoint: initialBreakpoint,
      breakpoints: [0, 0.25, 0.5, initialBreakpoint],
      handleBehavior: 'cycle'
    });
  }

  public async showSignInSignUpDialog(
    currentView: SiSuViewType,
    signUpOptionType: SignUpOptionType,
    showHeader = true,
    reloadAfterSuccess = true
  ): Promise<OverlayEventDetail<IDialogResponse<any>>> {
    return this.dialogsService.presentModal({
      component: SignInSignUpComponent,
      cssClass: 'sign-up-sign-in-modal',
      componentProps: {
        showHeader,
        currentView,
        signUpOptionType,
        reloadAfterSuccess
      }
    });
  }

  public navigateTo(view: 'all-games' | 'preview' | 'play', gameId?: number): void {
    if (view === 'all-games') {
      this.router.navigateByUrl(`${ROUTE_PATHS.casino}/games`);
    } else if (gameId && view === 'preview') {
      this.router.navigateByUrl(`${ROUTE_PATHS.casino}/games/${gameId}/preview`);
    } else if (gameId && view === 'play') {
      this.router.navigateByUrl(`${ROUTE_PATHS.casino}/games/${gameId}/play`);
    }
  }

  // Favorites
  public async updateTheListOfFavoriteGamesWithBackendData(): Promise<void> {
    try {
      const tmpList: Partial<ICasinoGameDetails<any, any>>[] = await this.casinoAbstractService.getFavoriteGames();
      this.playerFavoriteGamesList$.next(tmpList);
    } catch (error) {
      console.error('getListOfFavoriteGames error', error);
    }
  }

  public async toggleGameAsFavorite(game: Partial<ICasinoGameDetails<any, any>>): Promise<void> {
    // TODO: Perhaps we should somehow implement delay on the toggle button, so the user can't click it multiple times in a row
    if (!game?.id) {
      return;
    }

    const shouldBeRemovedFromFavorites: boolean = this.playerFavoriteGamesList$.value?.some((item: Partial<ICasinoGameDetails<any, any>>) => item?.id === game?.id);
    const updatedList: Partial<ICasinoGameDetails<any, any>>[] = updateFavoriteGamesList(game, shouldBeRemovedFromFavorites, this.playerFavoriteGamesList$.value);
    this.playerFavoriteGamesList$.next(updatedList);

    if (shouldBeRemovedFromFavorites) {
      await this.casinoAbstractService.removeGameFromFavorites(game?.id);
      await this.dialogsService.presentToast({ message: this.translateService.instant('commons.removedFromFavorites') });
    } else {
      await this.casinoAbstractService.addGameToFavorites(game?.id);
      await this.dialogsService.presentToast({ message: this.translateService.instant('commons.addedToFavorites') });
    }

    await this.updateTheListOfFavoriteGamesWithBackendData();
  }

  public async checkIfTheGameProperlyLoaded(gameId?: number): Promise<void> {
    if (!gameId || this.selectedGame$.value?.id === gameId) {
      return;
    }

    try {
      const tmpGame: Partial<ICasinoGameDetails<any, any>> | null = await this.casinoAbstractService.getCasinoGameById(gameId);
      this.selectedGame$.next(tmpGame);
    } catch (error) {
      console.log('Failed to properly load game by id', error);
    }
  }
}
