import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';

import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import { ITransaction, ITransactionInitiate } from 'bp-framework/dist/env-specific/betplatform/transactions/transactions.interface';
import { transformToTransactionDetails } from 'bp-framework/dist/env-specific/betplatform/transactions/transactions.mappers';
import {
  IAffiliateCode as IBpAffiliateCode,
  IReferredPlayer as IBpReferredPlayer,
  IPlayer,
  IPlayerRegisterPayload,
  ITokenPayload,
  IUser
} from 'bp-framework/dist/env-specific/betplatform/user/user.interface';
import { extractUserDetailsFromToken, mergeUserDetailsWithProfileData, transformRegisterPayloadToUserDetails } from 'bp-framework/dist/env-specific/betplatform/user/user.mappers';
import { IAffiliateCode, IReferredPlayer } from 'bp-framework/dist/player/player.interface';
import { ICryptoDepositInit, ITransactionDetails, TransactionType } from 'bp-framework/dist/transactions/transactions.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { BpCoreApiService, BpPlayerApiService, I18nService } from 'bp-angular-library';

import { UserAbstractService } from '../../env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { asyncScheduler } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserBetplatformService extends UserAbstractService {
  private bpCoreApiService: BpCoreApiService = inject(BpCoreApiService);
  private bpPlayerApiService: BpPlayerApiService = inject(BpPlayerApiService);
  private translateService: TranslateService = inject(TranslateService);
  private i18nService: I18nService = inject(I18nService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private authService: AuthenticationService = inject(AuthenticationService);

  public async playerRegisterWithUsernameAndPassword(username: string, password: string, phone: string, affiliateCode: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        console.log('playerRegisterWithUsernameAndPassword');
        // // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const registerPayload: IBpPayload<IPlayerRegisterPayload> | null = await this.bpPlayerApiService.playerRegisterWithUsernameAndPassword(
          username,
          password,
          phone,
          affiliateCode
        );

        if (!registerPayload?.data?.user?.id) {
          return reject(new Error(this.translateService.instant('notifications.userRegistrationFailedMissingId')));
        }

        const transformedData: Partial<IUserDetails> | null = transformRegisterPayloadToUserDetails(registerPayload?.data);

        await this.authService.userAuthChanged(transformedData);

        const loggedInUser: Partial<IUserDetails> | null = await this.loginWithUsernameAndPassword(username, password);

        return resolve(loggedInUser);
      } catch (error: unknown) {
        // TODO: Check if we can present error message instead of presenting custom message without any context.
        // TODO: Revisit error handling and error messages in the entire environment adapter
        return reject(new Error(this.translateService.instant('notifications.somethingWentWrong') + (error as any)?.message || ''));
      }
    });
  }

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const loginPayload: IBpPayload<ITokenPayload> | null = await this.bpCoreApiService.authenticateWithUsernameAndPassword(username, password);

        if (!loginPayload?.data?.access_token) {
          return reject(new Error(this.translateService.instant('notifications.failedToLoginCheckCredentials')));
        }

        const transformedLoginData: Partial<IUserDetails> | null = extractUserDetailsFromToken(loginPayload?.data);

        await this.authService.userAuthChanged(transformedLoginData);

        const getProfilePayload: IBpPayload<IPlayer> | null = await this.bpPlayerApiService.getPlayerProfile();

        if (!getProfilePayload) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
        }

        const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;

        const mergedValue: Partial<IUserDetails> | null = mergeUserDetailsWithProfileData(currentDetails, getProfilePayload?.data);

        await this.authService.userAuthChanged(mergedValue);

        return resolve(mergedValue);
      } catch (error) {
        // TODO: Check if we can present error message instead of presenting custom message without any context.
        // TODO: Revisit error handling and error messages in the entire environment adapter
        return reject(new Error(this.translateService.instant('notifications.failedToLoginCheckCredentialsOrTryLater')));
      }
    });
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const refreshToken: string | undefined = await this.authService.user$.value?.auth?.refreshToken;

        if (!refreshToken) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveRefreshToken')));
        }

        const loginPayload: IBpPayload<ITokenPayload> | null = await this.bpCoreApiService.refreshToken(refreshToken);

        if (!loginPayload?.data?.access_token) {
          return reject(new Error(this.translateService.instant('notifications.failedToRefreshToken')));
        }

        await this.authService.userAuthChanged(extractUserDetailsFromToken(loginPayload?.data));

        const getProfilePayload: IBpPayload<IPlayer> | null = await this.bpPlayerApiService.getPlayerProfile();

        if (!getProfilePayload) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
        }

        const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;

        const mergedValue: Partial<IUserDetails> | null = mergeUserDetailsWithProfileData(currentDetails, getProfilePayload?.data);

        await this.authService.userAuthChanged(mergedValue);

        return resolve(mergedValue);
      } catch (error: unknown) {
        return reject(error);
      }
    });
  }

  // TODO: Revisit naming of this method. It should be more descriptive. Everyone are 'user' but we also have 'player' and 'profile' in the same context
  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IBpPayload<IPlayer> | null = await this.bpPlayerApiService.getPlayerProfile();
        return resolve(userProfile ? mergeUserDetailsWithProfileData({}, userProfile.data) : null);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
      }
    });
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<null> {
    return new Promise<null>(async (resolve, reject) => {
      try {
        const result: IBpPayload<unknown> | null = await this.bpCoreApiService.changePassword(oldPassword, newPassword);
        return resolve(null);
      } catch (error) {
        // TODO: Add proper error handling and error messages with translations instead of hardcoding the message
        return reject(new Error(this.translateService.instant('notifications.failedToChangePassword')));
      }
    });
  }

  public async patchUserLanguage(lang: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IBpPayload<Partial<IUser>> | null = await this.bpCoreApiService.patchUserLanguage({
          language: lang
        });

        return resolve(userProfile ? mergeUserDetailsWithProfileData({}, userProfile?.data) : null);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
      }
    });
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    const freshData: Partial<IUserDetails> | null = await this.getUserProfile();
    await this.authService.userDetailsChanged(freshData);
    return this.authService.user$.value;
  }

  public watchForLanguageChange(): void {
    this.i18nService.saveNewlySelectedLanguageToUserProfile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((langAlpha2Code: string) => {
      // Handle additional actions on language change, e.g., refreshing data, updating UI, etc.
      // Update user language in the backend
      if (this.authService.user$.value?.id) {
        this.patchUserLanguage(langAlpha2Code);
        this.authService.userDetailsChanged({ attributes: { preferredLang: langAlpha2Code } });
      }
    });
  }

  public async getAffiliatePlayers(): Promise<IReferredPlayer[]> {
    return new Promise<IReferredPlayer[]>(async (resolve, reject) => {
      try {
        const result: IBpPayload<IBpReferredPlayer[]> | null = await this.bpPlayerApiService.getListOfPlayersReferredByPlayer();
        const transformedData: IReferredPlayer[] =
          result?.data?.map((player: IBpReferredPlayer) => {
            return {
              id: player.id,
              username: player.username,
              agentId: player.agent_id,
              affiliateParentUserId: player.affiliate_parent_user_id,
              registeredAt: player.registered_at
            };
          }) || [];
        return resolve(transformedData);
      } catch (error) {
        // TODO: Add proper error handling and error messages with translations instead of hardcoding the message
        return reject(new Error('Failed to retrieve affiliate players'));
      }
    });
  }

  public async getAffiliateCodes(): Promise<IAffiliateCode[]> {
    return new Promise<IAffiliateCode[]>(async (resolve, reject) => {
      try {
        const result: IBpPayload<IBpAffiliateCode[]> | null = await this.bpPlayerApiService.getListOfPlayersAffiliateCodes();
        const transformedData: IAffiliateCode[] =
          result?.data?.map((code: IBpAffiliateCode) => {
            return {
              id: code.id,
              code: code.code,
              userId: code.user_id,
              username: code.username,
              createdAt: code.created_at,
              updatedAt: code.updated_at
            };
          }) || [];
        return resolve(transformedData);
      } catch (error) {
        // TODO: Add proper error handling and error messages with translations instead of hardcoding the message
        return reject(new Error('Failed to retrieve affiliate codes'));
      }
    });
  }

  //#region Transactions
  public async getPlayerTransactions(
    start_date_iso: string,
    end_date_iso: string,
    limit: number,
    offset: number,
    transaction_types: TransactionType[]
  ): Promise<Partial<ITransactionDetails>[] | null> {
    return new Promise<Partial<ITransactionDetails>[] | null>(async (resolve, reject) => {
      try {
        const result: IBpPayload<ITransaction[]> | null = await this.bpPlayerApiService.getPlayerTransactions(start_date_iso, end_date_iso, limit, offset, transaction_types);

        const transformedData: Partial<ITransactionDetails>[] = result?.data?.map((transaction: ITransaction) => transformToTransactionDetails(transaction)) || [];

        return resolve(transformedData);
      } catch (error) {
        // TODO: Add proper error handling and error messages with translations instead of hardcoding the message
        return reject(new Error('Failed to retrieve list of transactions'));
      }
    });
  }

  public async getCryptoDepositInit(): Promise<ICryptoDepositInit | null> {
    return new Promise<ICryptoDepositInit | null>(async (resolve, reject) => {
      try {
        const tmpRequestInit: IBpPayload<ICryptoDepositInit> | null = await this.bpPlayerApiService.cryptoDepositInitiate();

        if (!tmpRequestInit?.data?.acquiringUrl) {
          return reject(new Error('Crypto Deposit initialization failed! Data is missing'));
        }
        return resolve(tmpRequestInit.data);
      } catch (error) {
        // TODO: Add proper error handling and error messages with translations instead of hardcoding the message
        return reject(new Error('Failed to initialize crypto deposit'));
      }
    });
  }

  public async makeCryptoWithdrawRequest(wallet_id: number, amount: number, crypto_wallet_address: string): Promise<Partial<ITransactionDetails> | null> {
    return new Promise<Partial<ITransactionDetails> | null>(async (resolve, reject) => {
      try {
        const tmpRequestInit: IBpPayload<ITransactionInitiate> | null = await this.bpPlayerApiService.cryptoWithdrawInitiate(wallet_id, amount, crypto_wallet_address);
        const tmpRequestSubmit: IBpPayload<ITransaction> | null = await this.bpPlayerApiService.cryptoWithdrawSubmit(tmpRequestInit?.data?.request_id || '');

        if (!tmpRequestSubmit?.data) {
          return reject(new Error('Withdraw request failed. Transaction data is missing.'));
        }
        const tmpTransaction: Partial<ITransactionDetails> = transformToTransactionDetails(tmpRequestSubmit.data);
        return resolve(tmpTransaction);
      } catch (error) {
        // TODO: Add proper error handling and error messages with translations instead of hardcoding the message
        return reject(new Error('Failed to make crypto withdraw request'));
      }
    });
  }

  public async cancelCryptoWithdrawRequest(request_id: string): Promise<Partial<ITransactionDetails> | null> {
    return new Promise<Partial<ITransactionDetails> | null>(async (resolve, reject) => {
      try {
        const tmpRequest: IBpPayload<ITransaction> | null = await this.bpPlayerApiService.cryptoWithdrawCancel(request_id);

        console.log('__>> vol 1 tmpRequest', tmpRequest);

        if (!tmpRequest?.data) {
          return reject(new Error('Transaction cancellation request failed. Transaction data is missing.'));
        }

        console.log('__>> vol 2 tmpRequest', tmpRequest);

        const tmpTransaction: Partial<ITransactionDetails> = transformToTransactionDetails(tmpRequest.data);
        return resolve(tmpTransaction);
      } catch (error) {
        // TODO: Add proper error handling and error messages with translations instead of hardcoding the message
        return reject(new Error('Failed to cancel crypto withdraw request'));
      }
    });
  }
  //#endregion
}
